<template>
  <div>
    <h4
      class="font-weight-bolder text-body"
      style="margin-bottom: 10px"
    >
      {{ $t('Add Primary Contact') }}
    </h4>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['first_name', 'middle_name', 'last_name', 'birthday', 'job_title']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bolder">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <label class="contacts-label font-weight-bolder">{{ $t('Contact Type') }}</label>
        <div
          v-for="field in ['billing', 'shipping', 'account_holder', 'can_place_orders']"
          :key="field"
          class="d-flex"
          style="margin-bottom: 10px"
        >
          <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin: 0"
          />
          <span class="ml-1">{{ fields[field].label }}</span>
        </div>

        <label class="contacts-label font-weight-bolder">{{ $t('Preferred contact method') }}</label>
        <div
          v-for="(field, index) in preferredContactMethod"
          :key="index"
          class="d-flex"
          style="margin-bottom: 10px"
        >
          <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin: 0"
          />
          <span class="ml-1">{{ field.title }}</span>
        </div>
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['email']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bolder">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <div
          v-for="field in ['phone_one', 'phone_two', 'phone_three', 'phone_four']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bolder">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h4
      class="font-weight-bolder text-body"
      style="margin-bottom: 10px"
    >
      {{ $t('Add Primary Address') }}
    </h4>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['street', 'city', 'state_id', 'country_id', ]"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bolder">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>

      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['line2','reference','zip']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel font-weight-bolder">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <label class="contacts-label font-weight-bolder">{{ $t('Address Type') }}</label>
        <div
          v-for="(field, index) in ['account_holder', 'billing', 'shipping']"
          :key="index"
          class="d-flex"
          style="margin-bottom: 10px"
        >
          <b-skeleton
            width="20px"
            height="20px"
            style="border-radius: 5px; margin: 0"
          />
          <span class="ml-1">{{ fields[field].label }}</span>
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BCol, BSkeleton,
} from 'bootstrap-vue'
import config from '../customConfig'

export default {
  name: 'FormCreateSkeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const {
      primaryContactInformation,
      accountParameters,
      moreContactInformation,
      primaryAddressInformation,
      preferredContactMethod,
    } = config()
    const accordionLabels = ['More Contact Information', 'Primary Address Information', 'Account Parameters']

    const fields = {
      ...accountParameters, ...primaryContactInformation, ...moreContactInformation, ...primaryAddressInformation,
    }
    return {
      fields,
      accordionLabels,
      preferredContactMethod,
    }
  },
}
</script>
